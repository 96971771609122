@import url(https://fonts.googleapis.com/css?family=Anton|Buenard:400,700);
article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,summary{display:block;}audio,canvas,video{display:inline-block;}audio:not([controls]){display:none;height:0;}[hidden]{display:none;}html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;}body{margin:0;}a:focus{outline:thin dotted;}a:active,a:hover{outline:0;}h1{font-size:2em;margin:0.67em 0;}abbr[title]{border-bottom:1px dotted;}b,strong{font-weight:bold;}dfn{font-style:italic;}hr{box-sizing:content-box;height:0;}mark{background:#ff0;color:#000;}code,kbd,pre,samp{font-family:monospace,serif;font-size:1em;}pre{white-space:pre-wrap;}q{quotes:"\201C" "\201D" "\2018" "\2019";}small{font-size:80%;}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}sup{top:-0.5em;}sub{bottom:-0.25em;}img{border:0;}svg:not(:root){overflow:hidden;}figure{margin:0;}fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:0.35em 0.625em 0.75em;}legend{border:0;padding:0;}button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}button,input{line-height:normal;}button,select{text-transform:none;}button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer;}button[disabled],html input[disabled]{cursor:default;}input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0;}input[type="search"]{-webkit-appearance:textfield;box-sizing:content-box;}input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}
*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	--color-text: #000;
	--color-bg: #ececec;
	--color-link: #000;
	--color-link-hover: #000;
	--color-info: #000;
	font-family: 'Buenard', serif;
	min-height: 100vh;
	color: #57585c;
	color: var(--color-text);
	background-color: #fff;
	background-color: var(--color-bg);
	display: grid;
  grid-template-rows: 1fr auto;
	/* background-position: 90% 80%;
	background-repeat: no-repeat;
	background-image: url("img/logo-bg.svg"); */
}
body::after {
  content: "";
	background-position: 90% 80%;
	background-repeat: no-repeat;
	background-image: url(/static/media/logo-bg.d8d66a72.svg);
  opacity: .8;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.preview-open {
	overflow: hidden;
}

/* Page Loader */
.js .loading::before {
	content: '';
	position: fixed;
	z-index: 100000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	content: '';
	position: fixed;
	z-index: 100000;
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	pointer-events: none;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	-webkit-animation: loaderAnim 0.7s linear infinite alternate forwards;
	        animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@-webkit-keyframes loaderAnim {
	to {
		opacity: 1;
		-webkit-transform: scale3d(0.5,0.5,1);
		        transform: scale3d(0.5,0.5,1);
	}
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		-webkit-transform: scale3d(0.5,0.5,1);
		        transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover,
a:focus {
	color: var(--color-link-hover);
	outline: none;
}

.hidden {
	position: absolute;
	overflow: hidden;
	width: 0;
	height: 0;
	pointer-events: none;
}

/* Icons */
.icon {
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin: 0 auto;
	fill: currentColor;
}

main {
	position: relative;
}

.content {
	position: relative;
}

/* Header */
.codrops-header {
	/* position: relative;
	padding: 2rem; */
	z-index: 100;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-align-items: center;
	        align-items: center;
	position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.codrops-header__title {
	font-size: 1em;
	font-weight: bold;
	margin: 0;
	padding: 0;
}

.info {
	margin: 0 0 0 1.25em;
	color: var(--color-info);
}

/* Top Navigation Style */
.codrops-links {
	position: relative;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 0 1em 0 0;
	text-align: center;
	white-space: nowrap;
}

.codrops-icon {
	display: inline-block;
	margin: 0.15em;
	padding: 0.25em;
}

.grid {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding-bottom: 5rem;
}

.grid__item {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	margin: 0 0 10rem 0;
	cursor: pointer;
}

.box {
	position: relative;
	margin: 2rem;
}

.box__title {
	margin: 0;
	line-height: 1;
	position: absolute;
	z-index: 100;
}

.box__title-inner {
	display: block;
	position: relative;
	font-weight: normal;
	text-transform: uppercase;
	font-size: 4.15rem;
	letter-spacing: 0.15rem;
	line-height: 1.25;
	font-family: 'Anton', sans-serif;
}

.box__title-inner[data-hover] {
	-webkit-text-stroke: 2px #000;
	text-stroke: 2px #000;
	-webkit-text-fill-color: transparent;
	text-fill-color: transparent;
	color: transparent;
}

.box__title-inner::before {
	content: attr(data-hover);
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	overflow: hidden;
	white-space: nowrap;
	-webkit-text-stroke: 0;
	text-stroke: 0;
	-webkit-text-fill-color: #000;
	text-fill-color: #000;
	color: #000;
	transition: all 0.3s;
}

.grid__item:hover .box__title-inner::before {
	height: 100%;
	width: 100%;
}

.box__text {
	margin: 0;
	position: absolute;
	top: -6rem;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 0.15rem;
	font-size: 1.75rem;
	margin: 0.5rem 1rem;
	font-family: 'Anton', sans-serif;
}

.box__text-inner {
	position: relative;
	display: block;
	border: 6px solid var(--color-text);
	padding: 0.25rem 1.25rem;
}

.box__text-inner--reverse {
	background: var(--color-text);
	color: var(--color-bg);
}

.box__img {
	display: block;
	-webkit-flex: none;
	        flex: none;
	margin: 0 auto;
	max-width: 100%;
	-webkit-filter: grayscale(1);
	        filter: grayscale(1);
	transition: -webkit-filter 0.3s;
	transition: filter 0.3s;
	transition: filter 0.3s, -webkit-filter 0.3s;
	pointer-events: none;
}

.grid__item:hover .box__img:not(.box__img--original) {
	-webkit-filter: grayscale(0);
	        filter: grayscale(0);
}

.box__shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	top: -1rem;
	left: -1rem;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAOklEQVQoU43MSwoAMAgD0eT+h7ZYaOlHo7N+DNHL2HAGgBWcyGcKbqTghTL4oQiG6IUpOqFEC5bI4QD8PAoKd9j4XwAAAABJRU5ErkJggg==);
}

.box__deco {
	font-size: 6rem;
	line-height: 1;
	font-weight: bold;
	position: absolute;
	bottom: -4rem;
	right: -4rem;
	display: none;
}

.box__content {
	position: absolute;
	max-width: 195px;
	font-size: 0.9rem;
	text-align: right;
	display: none;
}

.box__content strong {
	white-space: nowrap;
}

.overlay {
	pointer-events: none;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	overflow: hidden;
}

.overlay--open {
	pointer-events: auto;
}

.overlay__reveal {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #000;
	position: absolute;
	z-index: 100;
	-webkit-transform: translate3d(100%,0,0);
	        transform: translate3d(100%,0,0);
}

.overlay__item {
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	/* background: #ececec; */
	opacity: 0;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding: 5rem 5vw;
    -webkit-justify-content: center;
            justify-content: center;
}

.overlay__item .box {
	max-width: 600px;
	max-height: 100%;
	margin: 0;
}

.overlay__item .box__title {
	bottom: 0;
}

.box__img--original {
	max-height: calc(100vh - 10rem);
	-webkit-filter: none;
	        filter: none;
}

.overlay__close {
	position: absolute;
	top: 0;
	right: 0;
	background: none;
	border: 0;
	margin: 1rem;
	padding: 1rem;
	opacity: 0;
}

.overlay__close:focus {
	outline: none;
}

.overlay__content {
	/* font-size: .1rem; */
	line-height: 1.5;
	/* max-width: 25rem; */
	margin: 0rem 0 0 10vw;
}


@media screen and (min-width: 55em) {
	.grid {
		display: grid;
		-webkit-align-items: center;
		        align-items: center;
		padding: 12rem 3rem 10rem 3rem;
		grid-row-gap: 2rem;
		grid-template-columns: repeat(3,calc(100% / 3));
	}
	.grid__item {
		margin: 0;
	}
	.box {
		margin: 4rem;
	}
	.box__title {
		top: -4rem;
		right: -4.5rem;
		-webkit-writing-mode: vertical-rl;
		-ms-writing-mode: tb-rl;
		    writing-mode: vertical-rl;
	}
	.box__title--straight {
		-webkit-writing-mode: horizontal-tb;
		-ms-writing-mode: lr-tb;
		    writing-mode: horizontal-tb;
	}
	.box__title--straight .box__title-inner::before {
		width: 0;
		height: auto;
	}
	.box__title--bottom {
		bottom: -5rem;
		top: auto;
	}
	.box__title--left {
		left: -2rem;
		right: auto;
	}
	.box__text--topcloser {
		top: -5rem;
	}
	.box__text--bottom {
		bottom: -5rem;
		top: auto;
	}
	.box__text--bottomcloser {
		bottom: -5rem;
		top: auto;
	}
	.box__text--right {
		right: 0;
		left: auto;
	}
	.box__text-inner--rotated1 {
		-webkit-transform: rotate(4deg);
		        transform: rotate(4deg);
	}
	.box__text-inner--rotated2 {
		-webkit-transform: rotate(-3deg);
		        transform: rotate(-3deg);
	}
	.box__text-inner--rotated3 {
		-webkit-transform: rotate(-15deg);
		        transform: rotate(-15deg);
	}
	.box__deco {
		display: block;
	}
	.box__deco--left {
		right: auto;
		left: -3rem;
	}
	.box__deco--top {
		top: 0;
		bottom: auto;
	}
	.overlay__item {
		-webkit-flex-direction: row;
		        flex-direction: row;
	}
	.overlay__content {
		font-size: 1.25rem;
		margin-top: 0;
	}
	.overlay__item .box__title--bottom {
		bottom: 0;
	}
}

@media screen and (min-width: 80em) {
	.grid {
		grid-template-columns: repeat(4,25%);
	}
	.grid__item:nth-child(4n-2) {
		margin-top: -8rem;
		margin-bottom: 8rem;
	}
	.grid__item:nth-child(4n) {
		margin-top: -5rem;
	}
	.box {
		margin: 6rem 4.5rem;
	}
	.box__content {
		display: block;
	}
}

@media screen and (max-width: 55em) {
	.content {
		-webkit-flex-direction: column;
		        flex-direction: column;
		height: auto;
		min-height: 0;
	}
	.content--fixed {
		position: relative;
		z-index: 1000;
		display: block;
		padding: 0.85em;
	}
	.codrops-header {
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-align-items: center;
		        align-items: center;
		padding-bottom: 5rem;
	}
	.codrops-header__title {
		font-weight: bold;
		padding-bottom: 0.25em;
		text-align: center;
	}
	.info {
		margin: 0;
	}
	.github {
		display: block;
		margin: 1em auto;
	}
	.codrops-links {
		margin: 0;
	}
}


.ReactGridGallery_tile {
  z-index: 9;
}
.social {
	/* display: flex;
	text-align: right; */
	position: fixed;
	/* display: flex;
	justify-content: flex-end; */
	text-align: left;
  left: 0;
  bottom: 0;
  /* width: 100%; */
  /* text-align: right; */
	padding: 2rem 2rem 2rem;
	z-index: 99999;
}

.social > div {
	display: -webkit-flex;
	display: flex;
	padding: .3rem;
}

.social > .item > a {
	width: 25px !important;
	height: 25px !important;
}


.comic-text {
  font-size: 30px;
  font-weight: 900;
}

.social-icon .social-svg {
	color: #e01fa6;
	transition: 0.3s;
}

.social-icon  .social-svg:hover {
	color: #e01fa6;
	background: #e01fa6;
	box-shadow: 1px 1px 1px #c5c5c5;
	/* transform: scale(2); */
	-webkit-transform: rotate(1080deg);
	        transform: rotate(1080deg)
}


/* Smartphones (landscape) */
@media only screen and (min-width: 321px) {
	body::after {
		content: "";
		/* background-position: 90% 80%; */
		background-repeat: no-repeat;
		background-image: none;
		opacity: .8;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}

	.grid {
		display: grid;
		-webkit-align-items: center;
		        align-items: center;
		padding: 6rem 3rem 10rem 3rem;
	}


	.social {
		/* display: flex;
		text-align: right; */
		/* display: column; */
		/* position: fixed; */
		display: grid;
		-webkit-justify-content: flex-end;
		        justify-content: flex-end;
		text-align: right;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 1rem 1rem 1rem;
		z-index: 99999;
	}
}
/* Smartphones (landscape) */
@media only screen and (min-width : 1224px) {
	body::after {
		content: "";
		background-position: 90% 80%;
		background-repeat: no-repeat;
		background-image: url(/static/media/logo-bg.d8d66a72.svg);
		opacity: .8;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}

	.grid {
		grid-template-columns: repeat(4,25%);
		padding: 12rem 3rem 10rem 3rem;
	}

	.social {
		/* display: flex;
		text-align: right; */
		position: fixed;
		/* display: flex;
		justify-content: flex-end; */
		text-align: left;
		left: 0;
		bottom: 0;
		/* width: 100%; */
		/* text-align: right; */
		padding: 1rem 1rem 1rem;
		z-index: 99999;
	}
}

