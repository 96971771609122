.social {
	/* display: flex;
	text-align: right; */
	position: fixed;
	/* display: flex;
	justify-content: flex-end; */
	text-align: left;
  left: 0;
  bottom: 0;
  /* width: 100%; */
  /* text-align: right; */
	padding: 2rem 2rem 2rem;
	z-index: 99999;
}

.social > div {
	display: flex;
	padding: .3rem;
}

.social > .item > a {
	width: 25px !important;
	height: 25px !important;
}


.comic-text {
  font-size: 30px;
  font-weight: 900;
}

.social-icon .social-svg {
	color: #e01fa6;
	transition: 0.3s;
}

.social-icon  .social-svg:hover {
	color: #e01fa6;
	background: #e01fa6;
	box-shadow: 1px 1px 1px #c5c5c5;
	/* transform: scale(2); */
	transform: rotate(1080deg)
}


/* Smartphones (landscape) */
@media only screen and (min-width: 321px) {
	body::after {
		content: "";
		/* background-position: 90% 80%; */
		background-repeat: no-repeat;
		background-image: none;
		opacity: .8;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}

	.grid {
		display: grid;
		align-items: center;
		padding: 6rem 3rem 10rem 3rem;
	}


	.social {
		/* display: flex;
		text-align: right; */
		/* display: column; */
		/* position: fixed; */
		display: grid;
		justify-content: flex-end;
		text-align: right;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 1rem 1rem 1rem;
		z-index: 99999;
	}
}
/* Smartphones (landscape) */
@media only screen and (min-width : 1224px) {
	body::after {
		content: "";
		background-position: 90% 80%;
		background-repeat: no-repeat;
		background-image: url("../../img/logo-bg.svg");
		opacity: .8;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
		z-index: -1;
	}

	.grid {
		grid-template-columns: repeat(4,25%);
		padding: 12rem 3rem 10rem 3rem;
	}

	.social {
		/* display: flex;
		text-align: right; */
		position: fixed;
		/* display: flex;
		justify-content: flex-end; */
		text-align: left;
		left: 0;
		bottom: 0;
		/* width: 100%; */
		/* text-align: right; */
		padding: 1rem 1rem 1rem;
		z-index: 99999;
	}
}
